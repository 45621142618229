import axios from 'axios'

const client = axios.create()

client.interceptors.request.use(
  (config) => {
    return new Promise((resolve, reject) => {
      resolve(config)
    })
  },
  (error) => {
    return Promise.reject(error)
  }
)

client.interceptors.response.use(
  (response) => {
    if (
      response &&
      response.hasOwnProperty('data') &&
      response.hasOwnProperty('config')
    ) {
      // console.log(response.config.url, response.data);
    }

    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default client
