import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import withStyles from '@material-ui/core/styles/withStyles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import BookingInfoRoomStep from './BookingInfoRoomStep'
import { withTranslate } from 'react-redux-multilingual'
import CustomAppBar from './CustomAppBar'

class Checkin extends React.Component {
  state = { currentGuest: this.props.hospede }

  updateCurrentGuest(newCurrentGuest) {
    this.setState({ currentGuest: newCurrentGuest })
  }

  render() {
    const { classes, translate } = this.props

    const label = `Checkin Online - ${translate('roomLabel')} ${this.props.roomName
      } - ${translate('guestLabel')} ${this.state.currentGuest}`

    return (
      <React.Fragment>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <CustomAppBar customText={label} />
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <Grid item xs={12} sm={12} className={classes.grid}>
                <BookingInfoRoomStep
                  roomId={this.props.roomId}
                  totalGuests={this.props.totalGuests}
                  currentGuest={this.state.currentGuest}
                  updateCurrentGuest={this.updateCurrentGuest.bind(this)}
                />
              </Grid>
            </Paper>
          </main>
        </MuiThemeProvider>
      </React.Fragment>
    )
  }
}

const styles = (theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#303e46',
    boxShadow: 'none'
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(670 + theme.spacing(4))]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    backgroundColor: '#fff'
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(670 + theme.spacing(6))]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    },
    backgroundColor: '#fff'
  },
  paperTitle: {
    [theme.breakpoints.up(670 + theme.spacing(6))]: {
      marginBottom: theme.spacing(3),
      padding: theme.spacing(3),
      paddingTop: 0
    },
    backgroundColor: '#fff'
  },
  stepper: {
    padding: `${theme.spacing(3)}px 0 ${theme.spacing(5)}px`
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  title: {
    color: '#000'
  },
  heading: {
    color: '#fff',
    textAlign: 'center'
  },
  gridContainer: {
    paddingTop: 0
  },
  grid: {
    paddingTop: '0px!important'
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
})

const theme = createMuiTheme({
  typography: {
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    useNextVariants: true
  },
  palette: {
    primary: {
      main: '#455A64'
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      contrastText: '#ffcc00'
    }
  }
})

Checkin.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(withStyles(styles), connect())(withTranslate(Checkin))
