import { server } from './config'
import client from './client'

export default {
  getCheckin(data, config = {}) {
    const url = sessionStorage.getItem('url'),
      propertyId = sessionStorage.getItem('propertyId'),
      bookingId = sessionStorage.getItem('bookingId')

    return client.get(
      `${url}/checkin/api/booking?propertyId=${propertyId}&bookingId=${bookingId}&language=${data}`,
      {},
      config
    )
  },

  saveCheckin(
    data,
    config = { headers: { 'Content-Type': 'multipart/form-data' } }
  ) {
    const baseUrl = sessionStorage.getItem('url'),
      locale = sessionStorage.getItem('locale'),
      property = sessionStorage.getItem('propertyId'),
      booking = sessionStorage.getItem('bookingId')

    const url = `${baseUrl}/checkin/api/save?language=${locale}&propertyId=${property}&bookingId=${booking}`

    return client.post(url, data, config)
  },

  findReservation: function (data, config = {}) {
    const endpoint = `/checkin/api/findReservation?reservation=${data.reservationId}`

    return client.get(`${server}${endpoint}`, data, config)
  }
}
