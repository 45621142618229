import Cities from '../services/cities'

export default {
  async getCities(data) {
    let cities

    try {
      cities = await Cities.getCities(data)
    } catch (error) {
      cities = null
    }

    return cities ? cities.data : null
  }
}
