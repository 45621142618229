import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {IntlProvider, IntlReducer as Intl} from 'react-redux-multilingual'
import {combineReducers, createStore} from 'redux'
import {Provider} from 'react-redux'
import {translations} from './languages/Translations'

const ln = window.navigator.language, defaultLocale = ln.substring(0, 2), initialState = {component: ''};
const reducers = combineReducers(Object.assign({}, {Intl, component}));
const store = createStore(reducers, {Intl: {locale: (defaultLocale === 'pt' || defaultLocale === 'es' || defaultLocale === 'en' ? defaultLocale : 'en')}});

function component(state = initialState, action) {
  switch (action.type) {
    case 'SET_COMPONENT':
      return {
        ...state,
        component: action.component
      };
    default:
      return state;
  }
}

store.subscribe(() => {});

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider translations={translations}>
      <App/>
    </IntlProvider>
  </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
