import Question from '../services/questions'

export default {
  async getQuestions(data) {
    let question

    try {
      question = await Question.getQuestions(data)
    } catch (error) {
      question = false
    }

    return question ? question.data : false
  }
}
