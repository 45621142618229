import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withTranslate } from 'react-redux-multilingual'
import { Markup } from 'interweave'

class TermsAndConditions extends React.Component {
  state = {
    scroll: 'paper'
  }

  render() {
    const { translate } = this.props
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">
            {translate('termsAndConditionsTitle')}
          </DialogTitle>
          <DialogContent>
            <Markup content={this.props.content} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withTranslate(TermsAndConditions)
