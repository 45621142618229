import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import compose from 'recompose/compose'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import NativeSelect from '@material-ui/core/NativeSelect'
import Input from '@material-ui/core/Input'
import AccountCircle from '@material-ui/icons/AccountCircle'
import CalendarToday from '@material-ui/icons/CalendarToday'
import Phone from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'
import InputAdornment from '@material-ui/core/InputAdornment'
import MaskedInput from 'react-text-mask'
import {withTranslate} from 'react-redux-multilingual'
import Place from '@material-ui/icons/Place'
import LocationCity from '@material-ui/icons/LocationCity'
import States from '../modules/states'
import Cities from '../modules/cities'

class BookingInfoBasic extends React.Component {
  state = {
    fields: [],
    gender: -1,
    city: -1,
    stateList: [],
    cityList: [],
    fieldLoaded: false
  }

  constructor(props) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.DateFormatCustom = this.DateFormatCustom.bind(this)
    this.phoneRef = this.updateRef.bind(this, 'phone')
    this.birthDateRef = this.updateRef.bind(this, 'birthdate')
    this.nameRef = this.updateRef.bind(this, 'name')
    this.lastNameRef = this.updateRef.bind(this, 'lastName')
    this.emailRef = this.updateRef.bind(this, 'email')
    this.documentRef = this.updateRef.bind(this, 'document')
    this.taxIdRef = this.updateRef.bind(this, 'tax_id')
    this.addressRef = this.updateRef.bind(this, 'address')
    this.numberRef = this.updateRef.bind(this, 'number')
    this.complementRef = this.updateRef.bind(this, 'complement')
    this.neighborhoodRef = this.updateRef.bind(this, 'neighborhood')
    this.postalCodeRef = this.updateRef.bind(this, 'postalCode')
  }

  componentDidMount() {
    this.loadSelectedCEP().then(() => {});

    this.setState({
      gender: this.props.checkin.gender,
      city: this.props.checkin.city
    });
  }

  eraseStateList = () => {
    this.setState({
      stateList: []
    })
  }

  eraseCityList = () => {
    this.setState({
      cityList: []
    })
  }

  handleSelectedGender = (event) => {
    this.props.checkin.gender = event.target.value;

    this.setState({
      gender: this.props.checkin.gender
    })
  }

  handleSelectedCity = (event) => {
    this.props.checkin.city = event.target.value;

    this.setState({
      city: this.props.checkin.city
    })
  }

  countryChangeHandler = async (event) => {
    this.eraseStateList()
    this.eraseCityList()

    const selectedCountry = event.target.value
    const countryStates = await States.getCountryStates(selectedCountry)

    if (countryStates) {
      const listStates = []
      countryStates.result.states.forEach((items) => {
        listStates.push(items)
      })

      this.props.checkin.country = selectedCountry

      this.setState({
        stateList: listStates
      })
    }
  }

  countryStateChangeHandler = async (event) => {
    this.eraseCityList();

    const selectedState = event.target.value;

    const cities = await Cities.getCities(selectedState);

    if (cities) {
      const listCities = [];

      cities.result.cities.forEach((items) => {
        listCities.push(items)
      })

      this.props.checkin.state = selectedState;

      this.setState({
        cityList: listCities
      });
    }
  }

  loadSelectedCEP = async () => {
    if (this.props.checkin.country > 0) {
      const countryStates = await States.getCountryStates(
        this.props.checkin.country
      )
      if (countryStates) {
        const listStates = []
        countryStates.result.states.forEach((items) => {
          listStates.push(items)
        })
        this.setState({
          stateList: listStates
        })
      }
    }
    if (this.props.checkin.state > 0) {
      const cities = await Cities.getCities(this.props.checkin.state)
      if (cities) {
        const listCities = []
        cities.result.cities.forEach((items) => {
          listCities.push(items)
        })
        this.setState({
          cityList: listCities
        })
      }
    }
  }

  render() {
    this.loadDefaultData()

    const { classes, translate, checkinFieldsBehaviour: fields } = this.props

    return (
      <>
        <Grid container>
          <Grid container className={classes.gridItemClasses}>
            {fields.filter((e) => e.hasOwnProperty('field') && e.field === 'name')[0].visible && (
              <Grid item xs={12} sm={6} className={classes.gridItemClassesPad}>
                <TextField
                  inputRef={this.nameRef}
                  autoFocus
                  id="firstName"
                  name="firstName"
                  label={translate('firstNameLabel')}
                  value={this.props.checkin.name || ''}
                  onChange={this.props.handleChangeTF('name')}
                  onKeyPress={this.onElementKeyPress}
                  fullWidth
                  autoComplete="fname"
                  required={
                    fields.filter((e) => e.hasOwnProperty('field') && e.field === 'name')[0].required
                  }
                  error={
                    fields.filter((e) => e.hasOwnProperty('field') && e.field === 'name')[0].required &&
                    this.props.valid === false &&
                    this.props.checkin.name === ''
                  }
                  helperText={
                    fields.filter((e) => e.hasOwnProperty('field') && e.field === 'name')[0].required &&
                    (!this.props.valid && this.props.checkin.name === ''
                      ? this.props.translate('firstNameHelperText')
                      : '')
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                    maxLength: 50
                  }}
                />
              </Grid>
            )}

            {fields.filter((e) => e.hasOwnProperty('field') && e.field === 'surname')[0].visible && (
              <Grid item xs={12} sm={6}>
                <TextField
                  inputRef={this.lastNameRef}
                  id="lastName"
                  name="lastName"
                  label={translate('lastNameLabel')}
                  value={this.props.checkin.surname || ''}
                  onChange={this.props.handleChangeTF('surname')}
                  onKeyPress={this.onElementKeyPress}
                  className={classes.inputField}
                  fullWidth
                  autoComplete="lname"
                  required={
                    fields.filter((e) => e.hasOwnProperty('field') && e.field === 'surname')[0].required
                  }
                  error={
                    fields.filter((e) => e.hasOwnProperty('field') && e.field === 'surname')[0].required &&
                    this.props.valid === false &&
                    this.props.checkin.surname === ''
                  }
                  helperText={
                    fields.filter((e) => e.hasOwnProperty('field') && e.field === 'surname')[0].required &&
                    (!this.props.valid && this.props.checkin.surname === ''
                      ? this.props.translate('lastNameHelperText')
                      : '')
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                    maxLength: 50
                  }}
                />
              </Grid>
            )}
          </Grid>

          {fields.filter((e) => e.hasOwnProperty('field') && e.field === 'email')[0].visible && (
            <Grid item className={classes.gridItemClasses} xs={12} sm={6}>
              <TextField
                inputRef={this.emailRef}
                id="email"
                name="email"
                label={translate('emailLabel')}
                value={this.props.checkin.email || ''}
                onChange={this.props.handleChangeTF('email')}
                onKeyPress={this.onElementKeyPress}
                fullWidth
                autoComplete="email"
                required={fields.filter((e) => e.hasOwnProperty('field') && e.field === 'email')[0].required}
                error={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'email')[0].required &&
                  this.props.validEmail
                }
                helperText={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'email')[0].required &&
                  this.props.validEmail
                    ? this.props.translate('emailHelperText')
                    : ''
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                  maxLength: 50
                }}
              />
            </Grid>
          )}

          {fields.filter((e) => e.hasOwnProperty('field') && e.field === 'phone')[0].visible && (
            <Grid item className={classes.gridItemClasses} xs={12} sm={6}>
              <TextField
                inputRef={this.phoneRef}
                id="phone"
                name="phone"
                label={translate('telephoneLabel')}
                value={this.props.checkin.phone || ''}
                onChange={this.props.handleChangeTF('phone')}
                onKeyPress={this.onElementKeyPress}
                fullWidth
                autoComplete="telf"
                required={fields.filter((e) => e.hasOwnProperty('field') && e.field === 'phone')[0].required}
                error={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'phone')[0].required &&
                  this.props.valid === false &&
                  this.props.checkin.phone === ''
                }
                helperText={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'phone')[0].required &&
                  !this.props.valid &&
                  this.props.checkin.phone === ''
                    ? this.props.translate('telephoneHelperText')
                    : ''
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone />
                    </InputAdornment>
                  ),
                  maxLength: 20
                }}
              />
            </Grid>
          )}

          {fields.filter((e) => e.hasOwnProperty('field') && e.field === 'document')[0].visible && (
            <Grid item className={classes.gridItemClasses} xs={12} sm={6}>
              <TextField
                inputRef={this.documentRef}
                id="iddoc"
                name="iddoc"
                label={translate('documentLabel')}
                value={this.props.checkin.document || ''}
                onChange={this.props.handleChangeTF('document')}
                onKeyPress={this.onElementKeyPress}
                fullWidth
                required={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'document')[0].required
                }
                error={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'document')[0].required &&
                  this.props.validDocument
                }
                helperText={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'document')[0].required &&
                  this.props.validDocument
                    ? this.props.translate('documentHelperText')
                    : ''
                }
                autoComplete="iddoc"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  maxLength: 20
                }}
              />
            </Grid>
          )}

          {fields.filter((e) => e.hasOwnProperty('field') && e.field === 'tax_id')[0].visible && (
            <Grid item className={classes.gridItemClasses} xs={12} sm={6}>
              <TextField
                inputRef={this.taxIdRef}
                id="tax_id"
                name="tax_id"
                label={translate('taxIdLabel')}
                value={this.props.checkin.tax_id || ''}
                onChange={this.props.handleChangeTF('tax_id')}
                onKeyPress={this.onElementKeyPress}
                fullWidth
                required={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'tax_id')[0].required
                }
                error={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'tax_id')[0].required &&
                  this.props.valid === false &&
                  (this.props.checkin.tax_id === '' || this.props.checkin.tax_id === undefined)
                }
                helperText={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'tax_id')[0].required &&
                  !this.props.valid &&
                  (this.props.checkin.tax_id === '' || this.props.checkin.tax_id === undefined)
                    ? translate('taxIdHelperText')
                    : ''
                }
                autoComplete="tax_id"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  maxLength: 20
                }}
              />
            </Grid>
          )}

          {fields.filter((e) => e.hasOwnProperty('field') && e.field === 'date_of_birth')[0].visible && (
            <Grid item className={classes.gridItemClasses} xs={12} sm={6}>
              <TextField
                inputRef={this.birthDateRef}
                id="dateinput"
                name="dateinput"
                placeholder="dd/MM/yyyy"
                onFocus={this.onFocus}
                label={translate('dateinputLabel')}
                value={this.props.checkin.birth_date || ''}
                onChange={this.props.handleChangeTF('birth_date')}
                onKeyPress={this.onElementKeyPress}
                fullWidth
                autoComplete="dateinput"
                required={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'date_of_birth')[0].required
                }
                error={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'date_of_birth')[0]
                    .required && this.props.validDateBirth
                }
                helperText={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'date_of_birth')[0]
                    .required && this.props.validDateBirth
                    ? this.props.translate('dateinputHelperText')
                    : ''
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarToday />
                    </InputAdornment>
                  ),
                  inputComponent: this.DateFormatCustom
                }}
              />
            </Grid>
          )}

          {fields.filter((e) => e.hasOwnProperty('field') && e.field === 'gender')[0].visible && (
            <Grid item className={classes.gridItemClasses} xs={12} sm={6}>
              <NativeSelect
                name="gender"
                fullWidth
                value={this.state.gender}
                onChange={this.handleSelectedGender}
                input={<Input name="genderph" id="gender-label-placeholder" />}
                required={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'gender')[0].required
                }
                error={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'gender')[0].required &&
                  this.props.valid === false &&
                  this.props.checkin.gender < 0
                }
                inputProps={{
                  id: 'gender-required',
                  style: { height: 35 }
                }}
              >
                <option value="-1" disabled>
                  {translate('genderLabel')}
                </option>
                {this.props.genders.map((gender, index) => (
                  <option value={gender.id} key={index}>
                    {gender.name}
                  </option>
                ))}
              </NativeSelect>
              <FormHelperText>
                {fields.filter((e) => e.hasOwnProperty('field') && e.field === 'gender')[0].required &&
                !this.props.valid &&
                this.props.checkin.gender < 0
                  ? translate('genderHelperText')
                  : ''}
              </FormHelperText>
            </Grid>
          )}

          {fields.filter((e) => e.hasOwnProperty('field') && e.field === 'address')[0].visible && (
            <Grid item className={classes.gridItemClasses} xs={12} sm={6}>
              <TextField
                inputRef={this.addressRef}
                id="address"
                name="address"
                label={translate('addressLabel')}
                value={this.props.checkin.address || ''}
                required={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'address')[0].required
                }
                error={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'address')[0].required &&
                  this.props.valid === false &&
                  this.props.checkin.address === ''
                }
                helperText={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'address')[0].required &&
                  !this.props.valid &&
                  this.props.checkin.address === ''
                    ? translate('addressHelperText')
                    : ''
                }
                onChange={this.props.handleChangeTF('address')}
                onKeyPress={this.onElementKeyPress}
                className={classes.inputField}
                fullWidth
                autoComplete="address"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationCity />
                    </InputAdornment>
                  ),
                  maxLength: 50
                }}
              />
            </Grid>
          )}

          {fields.filter((e) => e.hasOwnProperty('field') && e.field === 'number')[0].visible && (
            <Grid item className={classes.gridItemClasses} xs={12} sm={6}>
              <TextField
                inputRef={this.numberRef}
                id="number"
                name="number"
                label={translate('numberLabel')}
                value={this.props.checkin.number || ''}
                required={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'number')[0].required
                }
                error={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'number')[0].required &&
                  this.props.valid === false &&
                  this.props.checkin.number === ''
                }
                helperText={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'number')[0].required &&
                  !this.props.valid &&
                  this.props.checkin.number === ''
                    ? translate('numberHelperText')
                    : ''
                }
                onChange={this.props.handleChangeTF('number')}
                onKeyPress={this.onElementKeyPress}
                className={classes.inputField}
                fullWidth
                autoComplete="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationCity />
                    </InputAdornment>
                  ),
                  maxLength: 5
                }}
              />
            </Grid>
          )}

          {fields.filter((e) => e.hasOwnProperty('field') && e.field === 'complement')[0].visible && (
            <Grid item className={classes.gridItemClasses} xs={12} sm={6}>
              <TextField
                inputRef={this.complementRef}
                id="complement"
                name="complement"
                label={translate('complementLabel')}
                value={this.props.checkin.complement || ''}
                required={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'complement')[0].required
                }
                error={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'complement')[0].required &&
                  this.props.valid === false &&
                  this.props.checkin.complement === ''
                }
                helperText={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'complement')[0].required &&
                  !this.props.valid &&
                  this.props.checkin.complement === ''
                    ? translate('complementHelperText')
                    : ''
                }
                onChange={this.props.handleChangeTF('complement')}
                onKeyPress={this.onElementKeyPress}
                className={classes.inputField}
                fullWidth
                autoComplete="complement"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationCity />
                    </InputAdornment>
                  ),
                  maxLength: 5
                }}
              />
            </Grid>
          )}

          {fields.filter((e) => e.hasOwnProperty('field') && e.field === 'neighborhood')[0].visible && (
            <Grid item className={classes.gridItemClasses} xs={12} sm={6}>
              <TextField
                inputRef={this.neighborhoodRef}
                id="neighborhood"
                name="neighborhood"
                label={translate('neighborhoodLabel')}
                value={this.props.checkin.neighborhood || ''}
                required={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'neighborhood')[0].required
                }
                error={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'neighborhood')[0].required &&
                  this.props.valid === false &&
                  this.props.checkin.neighborhood === ''
                }
                helperText={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'neighborhood')[0].required &&
                  !this.props.valid &&
                  this.props.checkin.neighborhood === ''
                    ? translate('neighborhoodHelperText')
                    : ''
                }
                onChange={this.props.handleChangeTF('neighborhood')}
                onKeyPress={this.onElementKeyPress}
                className={classes.inputField}
                fullWidth
                autoComplete="neighborhood"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationCity />
                    </InputAdornment>
                  ),
                  maxLength: 5
                }}
              />
            </Grid>
          )}

          {fields.filter((e) => e.hasOwnProperty('field') && e.field === 'post_code')[0].visible && (
            <Grid item className={classes.gridItemClasses} xs={12} sm={6}>
              <TextField
                inputRef={this.postalCodeRef}
                id="postalCode"
                name="postalCode"
                label={translate('postalCodeLabel')}
                value={this.props.checkin.postal_code || ''}
                required={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'post_code')[0].required
                }
                error={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'post_code')[0].required &&
                  this.props.valid === false &&
                  this.props.checkin.postal_code === ''
                }
                helperText={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'post_code')[0].required &&
                  !this.props.valid &&
                  this.props.checkin.postal_code === ''
                    ? translate('postalCodeHelperText')
                    : ''
                }
                onChange={this.props.handleChangeTF('postal_code')}
                onKeyPress={this.onElementKeyPress}
                className={classes.inputField}
                fullWidth
                autoComplete="postalCode"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Place />
                    </InputAdornment>
                  ),
                  maxLength: 50
                }}
              />
            </Grid>
          )}

          {fields.filter((e) => e.hasOwnProperty('field') && e.field === 'country')[0].visible && (
            <Grid item className={classes.gridItemClasses} xs={12} sm={6}>
              <NativeSelect
                name="country"
                fullWidth
                value={this.props.checkin.country}
                onChange={this.countryChangeHandler}
                input={
                  <Input name="countryph" id="country-label-placeholder" />
                }
                required={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'country')[0].required
                }
                error={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'country')[0].required &&
                  this.props.valid === false &&
                  this.props.checkin.country < 0
                }
                inputProps={{ id: 'country-required', style: { height: 35 } }}
              >
                <option value="-1" disabled>
                  {translate('countryLabel')}
                </option>
                {this.props.countries.map((country, index) => (
                  <option value={country.id} key={index}>
                    {country.name}
                  </option>
                ))}
              </NativeSelect>
              <FormHelperText>
                {fields.filter((e) => e.hasOwnProperty('field') && e.field === 'country')[0].required &&
                !this.props.valid &&
                (this.props.checkin.country === null ||
                  this.props.checkin.country < 0)
                  ? translate('countryHelperText')
                  : ''}
              </FormHelperText>
            </Grid>
          )}

          {fields.filter((e) => e.hasOwnProperty('field') && e.field === 'state')[0].visible && (
            <Grid item className={classes.gridItemClasses} xs={12} sm={6}>
              <NativeSelect
                name="state"
                fullWidth
                value={this.props.checkin.state}
                onChange={this.countryStateChangeHandler}
                input={<Input name="stateph" id="state-label-placeholder" />}
                required={fields.filter((e) => e.hasOwnProperty('field') && e.field === 'state')[0].required}
                error={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'state')[0].required &&
                  this.props.valid === false &&
                  this.props.checkin.state < 0
                }
                inputProps={{ id: 'state-required', style: { height: 35 } }}
              >
                <option value="-1" disabled>
                  {translate('stateLabel')}
                </option>
                {this.state.stateList.map((countryState, index) => (
                  <option value={countryState.id} key={index}>
                    {countryState.name}
                  </option>
                ))}
              </NativeSelect>
              <FormHelperText>
                {fields.filter((e) => e.hasOwnProperty('field') && e.field === 'state')[0].required &&
                !this.props.valid &&
                (this.props.checkin.state === null ||
                  this.props.checkin.state < 0)
                  ? translate('stateHelperText')
                  : ''}
              </FormHelperText>
            </Grid>
          )}

          {fields.filter((e) => e.hasOwnProperty('field') && e.field === 'city')[0].visible && (
            <Grid item className={classes.gridItemClasses} xs={12} sm={6}>
              <NativeSelect
                name="city"
                fullWidth
                value={this.state.city}
                onChange={this.handleSelectedCity}
                input={<Input name="stateph" id="city-label-placeholder" />}
                required={fields.filter((e) => e.hasOwnProperty('field') && e.field === 'city')[0].required}
                error={
                  fields.filter((e) => e.hasOwnProperty('field') && e.field === 'city')[0].required &&
                  this.props.valid === false &&
                  this.props.checkin.city < 0
                }
                inputProps={{ id: 'city-required', style: { height: 35 } }}
              >
                <option value="-1" disabled>
                  {translate('cityLabel')}
                </option>
                {this.state.cityList.map((city, index) => (
                  <option value={city.id} key={index}>
                    {city.name}
                  </option>
                ))}
              </NativeSelect>
              <FormHelperText>
                {fields.filter((e) => e.hasOwnProperty('field') && e.field === 'city')[0].required &&
                !this.props.valid &&
                (this.props.checkin.city === null ||
                  this.props.checkin.city < 0)
                  ? translate('cityHelperText')
                  : ''}
              </FormHelperText>
            </Grid>
          )}
        </Grid>
      </>
    )
  }

  loadDefaultData() {
    const load =
      process.env.hasOwnProperty('REACT_APP_GUEST_LOAD_DEFAULT_DATA') &&
      process.env.REACT_APP_GUEST_LOAD_DEFAULT_DATA.toString().toLowerCase() === 'yes'

    if (load) {
      let name = '',
        surname = '',
        email = '',
        phone = '',
        document = '',
        taxId = '',
        birthDate = '',
        gender = '',
        country = '',
        address = '',
        number = '',
        complement = '',
        neighborhood = '',
        city = '',
        state = '',
        zipcode = ''

      if (process.env.hasOwnProperty('REACT_APP_GUEST_NAME')) {
        name = process.env.REACT_APP_GUEST_NAME
      }

      if (process.env.hasOwnProperty('REACT_APP_GUEST_SURNAME')) {
        surname = process.env.REACT_APP_GUEST_SURNAME
      }

      if (process.env.hasOwnProperty('REACT_APP_GUEST_EMAIL')) {
        email = process.env.REACT_APP_GUEST_EMAIL
      }

      if (process.env.hasOwnProperty('REACT_APP_GUEST_PHONE_NUMBER')) {
        phone = process.env.REACT_APP_GUEST_PHONE_NUMBER
      }

      if (process.env.hasOwnProperty('REACT_APP_GUEST_DOCUMENT')) {
        document = process.env.REACT_APP_GUEST_DOCUMENT
      }

      if (process.env.hasOwnProperty('REACT_APP_GUEST_TAX_ID')) {
        taxId = process.env.REACT_APP_GUEST_TAX_ID
      }

      if (process.env.hasOwnProperty('REACT_APP_GUEST_BIRTH_DATE')) {
        birthDate = process.env.REACT_APP_GUEST_BIRTH_DATE
      }

      if (process.env.hasOwnProperty('REACT_APP_GUEST_GENDER')) {
        gender = process.env.REACT_APP_GUEST_GENDER
      }

      if (process.env.hasOwnProperty('REACT_APP_GUEST_ADDRESS')) {
        address = process.env.REACT_APP_GUEST_ADDRESS
      }

      if (process.env.hasOwnProperty('REACT_APP_GUEST_NUMBER')) {
        number = process.env.REACT_APP_GUEST_NUMBER
      }

      if (process.env.hasOwnProperty('REACT_APP_GUEST_COMPLEMENT')) {
        complement = process.env.REACT_APP_GUEST_COMPLEMENT
      }

      if (process.env.hasOwnProperty('REACT_APP_GUEST_NEIGHBORHOOD')) {
        neighborhood = process.env.REACT_APP_GUEST_NEIGHBORHOOD
      }

      if (process.env.hasOwnProperty('REACT_APP_GUEST_CITY')) {
        city = process.env.REACT_APP_GUEST_CITY
      }

      if (process.env.hasOwnProperty('REACT_APP_GUEST_COUNTRY')) {
        country = process.env.REACT_APP_GUEST_COUNTRY
      }

      if (process.env.hasOwnProperty('REACT_APP_GUEST_STATE')) {
        state = process.env.REACT_APP_GUEST_STATE
      }

      if (process.env.hasOwnProperty('REACT_APP_GUEST_ZIPCODE')) {
        zipcode = process.env.REACT_APP_GUEST_ZIPCODE
      }

      this.props.checkin.name = name
      this.props.checkin.surname = surname
      this.props.checkin.email = email
      this.props.checkin.phone = phone
      this.props.checkin.document = document
      this.props.checkin.tax_id = taxId
      this.props.checkin.birth_date = birthDate
      this.props.checkin.gender = gender
      this.props.checkin.address = address
      this.props.checkin.number = number
      this.props.checkin.complement = complement
      this.props.checkin.neighborhood = neighborhood
      this.props.checkin.country = country
      this.props.checkin.city = city
      this.props.checkin.state = state
      this.props.checkin.postal_code = zipcode
    }
  }

  updateRef(name, ref) {
    this[name] = ref
  }

  onFocus() {
    if (this.props.birthdate === '') {
      this.birthdate.selectionStart = 0
      this.birthdate.selectionEnd = 0
    }
  }

  DateFormatCustom(props) {
    const { inputRef, ...other } = props
    return (
      <MaskedInput
        {...other}
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null)
        }}
        placeholder={this.props.translate('dateformat')}
        placeholderChar={'\u2000'}
      />
    )
  }

  onElementKeyPress = (ev) => {
    if (ev.key === 'Enter') {
    }
  }
}

const styles = (theme) => ({
  DateFormatInput: {
    '& div div': {
      height: '31px'
    }
  },
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#f2994a'
    }
  },
  heading: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(18),
    width: '100%',
    paddingTop: '12px',
    paddingLeft: '12px'
  },
  inputField: {
    marginTop: 15
  },
  gridItemClassesPad: {
    paddingTop: 15
  },
  gridItemClasses: {
    paddingTop: 15,
    paddingBottom: 15
  }
})

BookingInfoBasic.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(
  withStyles(styles),
  connect()
)(withTranslate(BookingInfoBasic))
