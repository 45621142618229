import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from '@material-ui/lab'
import { Box } from '@material-ui/core'

class ErrorMessage extends React.Component {
  render() {
    const { message } = this.props
    return (
      <Box mt={2}>
        <Alert severity="error">{message}</Alert>
      </Box>
    )
  }
}

Error.propTypes = {
  message: PropTypes.object.isRequired
}

export default ErrorMessage
