import React from 'react'
import Grid from '@material-ui/core/Grid'
import TickInside from '../assets/tick_inside.png'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import green from '@material-ui/core/colors/green'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { withTranslate } from 'react-redux-multilingual'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import IconButton from '@material-ui/core/IconButton'
import LinkIcon from '@material-ui/icons/Link'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import MailIcon from '@material-ui/icons/Mail'
import Tooltip from '@material-ui/core/Tooltip'
import Snackbar from '@material-ui/core/Snackbar'

import { isMobileOrTablet } from '../helpers/device.js'
import { getWhatsappLink, getEmailLink } from '../helpers/share.js'

class Success extends React.Component {
  state = {
    open: false,
    vertical: 'top',
    horizontal: 'center'
  }

  handleClick = (state) => () => {
    const textField = document.createElement('textarea')
    textField.innerText = this.props.edp
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()

    this.setState({ open: true, ...state })
  }

  handleClickShareWhatsapp = (translate) => {
    const url = this.props.edp
    const isMobile = isMobileOrTablet()
    const title =
      this.props.propertyName + ' - ' + translate('shareCheckinOnlineMsg')

    window.location.href = getWhatsappLink(url, title, isMobile)
  }

  handleClickShareEmail = (translate) => {
    const url = this.props.edp
    const subject = this.props.propertyName + ' - Check in Online'
    const fullBody = translate('shareCheckinOnlineMsg') + ' ' + url

    window.location.href = getEmailLink(subject, fullBody)
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  gotoHome = () => {
    let url = this.props.edp

    if (this.props.continue) {
      window.location.href = url
    } else {
      window.location.reload()
    }
  }

  render() {
    const { classes, translate } = this.props
    const { vertical, horizontal, open } = this.state
    return (
      <React.Fragment>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              marginTop: 10,
              textAlign: 'center'
            }}
          >
            <img src={TickInside} alt="tick" height="100" width="100" />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography component="h4" variant="h4">
              {translate('successTitle')}
            </Typography>
            <Typography component="h4" variant="h4">
              {translate('successSubTitle')}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography component="h6" variant="h6">
              {translate('successText')}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              className={classNames(classes.margin, classes.cssRoot)}
              onClick={this.gotoHome}
            >
              {this.props.continue
                ? translate('checkinNextLabel')
                : translate('nextLabel')}
            </Button>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Tooltip title={translate('shareLinkLabel')}>
              <IconButton
                color="primary"
                className={classes.button}
                component="span"
                size="medium"
                onClick={this.handleClick({
                  vertical: 'bottom',
                  horizontal: 'center'
                })}
              >
                <LinkIcon className={classes.largeIcon} />
              </IconButton>
            </Tooltip>

            <Tooltip title={translate('shareLinkWhatsappLabel')}>
              <IconButton
                color="primary"
                className={classes.button}
                component="span"
                size="medium"
                onClick={() => {
                  this.handleClickShareWhatsapp(translate)
                }}
              >
                <WhatsAppIcon className={classes.largeIcon} />
              </IconButton>
            </Tooltip>

            <Tooltip title={translate('shareLinkEmailLabel')}>
              <IconButton
                color="primary"
                className={classes.button}
                component="span"
                size="medium"
                onClick={() => {
                  this.handleClickShareEmail(translate)
                }}
              >
                <MailIcon className={classes.largeIcon} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={<span id="message-id">{translate('linkSharedLabel')}</span>}
        />
      </React.Fragment>
    )
  }
}

Success.propTypes = {
  classes: PropTypes.object.isRequired
}

const styles = (theme) => ({
  margin: {
    margin: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  cssRoot: {
    color: '#fff',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  largeIcon: {
    width: 35,
    height: 35
  }
})

export default compose(withStyles(styles), connect())(withTranslate(Success))
