import Reservation from '../services/reservation'

export default {
  async getCheckin(data) {
    let checkin

    try {
      checkin = await Reservation.getCheckin(data)
    } catch (error) {
      checkin = false
    }

    return checkin ? checkin.data : false
  },

  async saveCheckin(data) {
    let checkin = true

    try {
      const formData = new FormData()

      Object.keys(data).forEach((key) => formData.append(key, data[key]))

      checkin = await Reservation.saveCheckin(formData)
    } catch (error) {
      checkin = false
    }
    return checkin ? checkin.data : false
  },

  async findReservation(data) {
    let checkin

    try {
      checkin = await Reservation.findReservation(data)
    } catch (error) {
      checkin = false
    }

    return checkin ? checkin.data : false
  }
}
