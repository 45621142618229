import Country from '../services/countries'

export default {
  async getCountries(data) {
    let country

    try {
      country = await Country.getCountries(data)
    } catch (error) {
      country = false
    }

    return country ? country.data : false
  }
}
