export const translations = {
  pt: {
    locale: 'pt-br',
    messages: {
      additionalFieldHelperText: 'Digite as informações adicionais',
      additionalFieldLabel: 'Informações adicionais',
      tableHeaderResId: 'ID da reserva',
      tableHeaderName: 'Nome',
      tableHeaderCheckin: 'Check In',
      tableHeaderCheckout: 'Check Out',
      findYourReservationByNameOrEmail: 'Encontre sua reserva pelo email',
      clickHere: 'Clique aqui',
      or: 'ou',
      search: 'Buscar',
      andSearchForYourData: 'e pesquise pelos seus dados',
      findPropertySubHeader: 'Para qual hostel ou hotel você está indo?',
      findPropertyByName: 'Busque pelo nome',
      homeSubHeader: 'Insira o Localizador da Reserva',
      localizadorNotFound: 'Não foi possível encontrar a reserva',
      txtName: 'Nome',
      txtEmail: 'E-mail',
      localizadorLabel: 'Localizador da Reserva',
      localizadorError: 'Insira o localizador da reserva',
      previousLabel: 'Retornar',
      nextLabel: 'Prosseguir',
      saveLabel: 'Salvar',
      phoneLabel: 'Telefone: ',
      guestsLabel: 'Hospedes: ',
      guestLabel: 'Hospede ',
      reservationTitle: 'Reserva',
      chooseRoomLabel: 'Escolha o quarto e preencha seus dados ',
      roomLabel: 'Quarto ',
      guestSubtitle: 'Os hóspedes a registar: ',
      reservationLabel: 'Reserva ',
      firstNameLabel: 'Nome',
      firstNameHelperText: 'Digite seu nome',
      lastNameLabel: 'Sobrenome',
      lastNameHelperText: 'Digite seu sobrenome',
      emailLabel: 'E-mail',
      emailHelperText: 'Digite um email válido',
      telephoneLabel: 'Telefone',
      telephoneHelperText: 'Digite o número de telefone',
      documentLabel: 'Documento de identificação',
      documentHelperText: 'Digite seu Documento de identificação',
      dateinputLabel: 'Data de Nascimento',
      dateinputHelperText: 'Digite seu Data de Nascimento',
      genderLabel: 'Sexo',
      genderHelperText: 'Selecione um sexo',
      mandatoryField: 'Necessário',
      countryLabel: 'País',
      countryHelperText: 'Selecione um país',
      uploadPhotoText: 'Carregar uma foto',
      uploadPhotoHelperText: 'Envie sua melhor foto para nossos registros',
      uploadDocumentPhotoText: 'Carregar a foto do documento',
      uploadDocumentPhotoHelperText:
        'Carregue uma imagem nítida do seu documento com foto (ex: RG, CNH, PASSAPORTE)',
      uploadDocumentRequired: 'É obrigatório o envio de uma foto de seu documento',
      uploadPhotoRequired: 'É obrigatório o envio de uma foto',
      question1Label: 'De onde você está vindo?',
      questionHelperText: 'Responda esta pergunta',
      question2Label: 'Qual seu próximo destino?',
      question3Label: 'Qual é o motivo da sua viagem?',
      question4Label:
        'Qual o principal motivo pelo qual você escolheu nosso hotel',
      question5Label:
        'Você gostaria de receber informações sobre bons hotels na sua próxima parada?',
      acceptTermsLabel: 'Ler e aceitar os termos e condições',
      acceptTermsHelperText: 'Você deve aceitar os termos',
      successTitle: 'Sucesso!',
      successSubTitle: 'Você fez checkin',
      successText: 'Suas informações foram registradas com sucesso.',
      moreAboutYouLabel: 'Conte-nos um pouco mais sobre você',
      errorTitle: 'Erro!',
      errorSubTitle: 'Ocorreu um erro',
      errorText: 'Erro inesperado ao tentar salvar, clique em prosseguir',
      dateformat: 'dd/MM/yyyy',
      emptyCheckin: 'Não tem convidados para se inscrever',
      termsAndConditionsTitle: 'Termos e Condições',
      checkinNextLabel: 'Registre outro hóspede',
      shareLinkLabel: 'Compartilhar',
      shareLinkCopyLabel: 'Copiar Link p/ Compartilhamento',
      shareLinkWhatsappLabel: 'Compartilhar p/ Whatsapp',
      shareCheckinOnlineMsg: 'Faça seu checkin online através da URL',
      shareLinkEmailLabel: 'Compartilhar p/ E-mail',
      linkSharedLabel: 'Link copiado',
      addressLabel: 'Endereço',
      addressHelperText: 'Digite seu endereço',
      numberLabel: 'Número',
      numberHelperText: 'Digite o número do seu endereço',
      complementLabel: 'Complemento',
      complementHelperText: 'Digite o complemento do seu endereço',
      neighborhoodLabel: 'Bairro',
      neighborhoodHelperText: 'Digite seu bairro',
      taxIdLabel: 'CPF',
      taxIdHelperText: 'Digite seu CPF',
      cityLabel: 'Cidade',
      cityHelperText: 'Digite sua cidade',
      stateLabel: 'Estado',
      stateHelperText: 'Digite seu status',
      postalCodeLabel: 'CEP',
      postalCodeHelperText: 'Digite seu CEP',
      paymentLabel: 'Pagamento',
      debitLabel: 'Saldo devedor da reserva:',
      ccCardNumberLabel: 'Número do Cartão',
      ccFullNameLabel: 'Nome',
      ccValidThruLabel: 'Validade',
      ccCodeLabel: 'CVC',
      ccYourName: 'Seu Nome',
      ccPaymentAmount: 'Valor a Pagar',
      paymentInfo:
        '* Caso a reserva já esteja quitada, ou deseje pagar de outra forma, ',
      paymentInfoAction: 'clique AQUI',
      paymentAmountInputHelperText:
        'O valor não pode ultrapassar o valor total devido.',
      ccCardNumberInputHelperText: 'O número informado não é válido.',
      ccFullNameInputHelperText: 'Informe o nome impresso no cartão.',
      ccValidThruInputHelperText: 'Informe uma data válida.',
      ccCodeInputHelperText: 'Informe um código válido.'
    }
  },
  en: {
    locale: 'en-US',
    messages: {
      additionalFieldHelperText: 'Enter additional information',
      additionalFieldLabel: 'Additional Information',
      tableHeaderResId: 'Reservation ID',
      tableHeaderName: 'Name',
      tableHeaderCheckin: 'Check In',
      tableHeaderCheckout: 'Check Out',
      findYourReservationByNameOrEmail: 'Find your reservation by its e-mail',
      clickHere: 'Click here',
      or: 'or',
      search: 'Search',
      txtName: 'Name',
      txtEmail: 'E-mail',
      andSearchForYourData: 'and search for your data',
      findPropertySubHeader: 'Which hostel or hotel are you going to?',
      findPropertyByName: 'Search by name',
      homeSubHeader: 'Enter Reservation Locator',
      localizadorNotFound: 'Booking Not Found',
      localizadorLabel: 'Booking Reference',
      localizadorError: 'Enter Reservation Locator',
      previousLabel: 'Previous',
      nextLabel: 'Next',
      saveLabel: 'Save',
      phoneLabel: 'Phone: ',
      guestLabel: 'Guest ',
      guestsLabel: 'Guests: ',
      reservationTitle: 'Reservation',
      chooseRoomLabel: 'Choose a room and fill in your data',
      roomLabel: 'Room ',
      guestSubtitle: 'Guests to check in: ',
      reservationLabel: 'Reservation ',
      firstNameLabel: 'First Name',
      firstNameHelperText: 'Type your name',
      lastNameLabel: 'Last Name',
      lastNameHelperText: 'Type your last name',
      emailLabel: 'E-mail',
      emailHelperText: 'Type a valid email address',
      telephoneLabel: 'Phone',
      telephoneHelperText: 'Type your phone number',
      documentLabel: 'Identification document',
      documentHelperText: 'Type your ID',
      dateinputLabel: 'Date of birth',
      dateinputHelperText: 'Type your birth date',
      genderLabel: 'Gender',
      genderHelperText: 'Select a gender',
      mandatoryField: 'Required',
      countryLabel: 'Country',
      countryHelperText: 'Select a Country',
      uploadPhotoText: 'Upload a photo',
      uploadPhotoHelperText: 'Send your best photo to our records',
      uploadDocumentPhotoText: 'Upload a document photo',
      uploadDocumentPhotoHelperText:
        'Upload a clear image of your photo document (eg: ID, PASSPORT)',
      uploadDocumentRequired: 'The upload of your photo document is required',
      uploadPhotoRequired: 'The upload of a self picture is required',
      question1Label: 'Where are you coming from?',
      questionHelperText: 'Answer this question',
      question2Label: 'What is your next destination?',
      question3Label: 'What is the purpose of your trip?',
      question4Label: 'What is the main reason you chose our hotel?',
      question5Label:
        'Would you like to receive information about good hotels at your next stop?',
      acceptTermsLabel: 'Read and accept the terms and conditions',
      acceptTermsHelperText: 'You must accept the terms and conditions',
      successTitle: 'Success!',
      successSubTitle: 'You checked in.',
      successText: 'Your information has been successfully saved',
      moreAboutYouLabel: 'Tell us a little more about yourself',
      errorTitle: 'Sorry',
      errorSubTitle: 'Error',
      errorText: 'Something went wrong trying to save, click Next',
      dateformat: 'MM/dd/yyyy',
      emptyCheckin: 'You do not have guests to register',
      termsAndConditionsTitle: 'Terms and Conditions',
      checkinNextLabel: 'Register another Guest',
      shareLinkLabel: 'Share',
      shareLinkCopyLabel: 'Copy Link for Sharing',
      shareLinkWhatsappLabel: 'Share w/ Whatsapp',
      shareCheckinOnlineMsg:
        'Check in online your reservation using the follow link',
      shareLinkEmailLabel: 'Share w/ E-mail',
      linkSharedLabel: 'Link copied',
      addressLabel: 'Address',
      addressHelperText: 'Type your address',
      numberLabel: 'Number',
      numberHelperText: 'Type your address number',
      complementLabel: 'Complement',
      complementHelperText: 'Type your address complement',
      neighborhoodLabel: 'Neighborhood',
      neighborhoodHelperText: 'Type your neighborhood',
      taxIdLabel: 'Tax ID',
      taxIdHelperText: 'Type your Tax ID',
      cityLabel: 'City',
      cityHelperText: 'Type your city',
      stateLabel: 'State',
      stateHelperText: 'Type your state',
      postalCodeLabel: 'Zip Code',
      postalCodeHelperText: 'Type your zip code',
      paymentLabel: 'Payment',
      debitLabel: 'Reserve balance due:',
      ccCardNumberLabel: 'Card Number',
      ccFullNameLabel: 'Holder Name',
      ccValidThruLabel: 'Valid Thru',
      ccCodeLabel: 'CVC',
      ccYourName: 'Your Name',
      ccPaymentAmount: 'Payment Amount',
      paymentInfo:
        '* In case the reservation is already paid or you wish to use another payment method, ',
      paymentInfoAction: 'click HERE',
      paymentAmountInputHelperText:
        'The value cannot exceed the total amount due.',
      ccCardNumberInputHelperText: 'The informed number is not valid.',
      ccFullNameInputHelperText: 'Inform the name printed in the card.',
      ccValidThruInputHelperText: 'Inform a valid date.',
      ccCodeInputHelperText: 'Inform a valid code.'
    }
  },
  es: {
    locale: 'es-ES',
    messages: {
      additionalFieldHelperText: 'Ingrese información adicional',
      additionalFieldLabel: 'Informaciones adicionales',
      tableHeaderResId: 'ID de reserva',
      tableHeaderName: 'Nombre',
      tableHeaderCheckin: 'Check In',
      tableHeaderCheckout: 'Check Out',
      findYourReservationByNameOrEmail:
        'Encuentra tu reserva por el correo electrónico',
      clickHere: 'haga clic aquí',
      or: 'ou',
      search: 'Buscar',
      txtName: 'Nombre',
      txtEmail: 'E-mail',
      andSearchForYourData: 'y busca tus datos',
      findPropertySubHeader: '?A qué hostal vas a ir?',
      findPropertyByName: 'Busque por el nombre',
      homeSubHeader: 'Ingrese el localizador de la reserva',
      localizadorNotFound: 'No se encontró la reserva',
      localizadorLabel: 'Localizador de Reserva',
      localizadorError: 'Ingrese el localizador de la reserva',
      previousLabel: 'Anterior',
      nextLabel: 'Siguiente',
      saveLabel: 'Guardar',
      phoneLabel: 'Teléfono: ',
      guestLabel: 'Huésped ',
      guestsLabel: 'Huéspedes: ',
      reservationTitle: 'Reserva',
      chooseRoomLabel: 'Escoja un cuarto y rellene sus datos',
      roomLabel: 'Cuarto ',
      guestSubtitle: 'Huéspedes a Registrar: ',
      reservationLabel: 'Reservación ',
      firstNameLabel: 'Nombre',
      firstNameHelperText: 'Ingrese su nombre',
      lastNameLabel: 'Apellido',
      lastNameHelperText: 'Ingrese su apellido',
      emailLabel: 'Correo Eléctronico',
      emailHelperText: 'Ingrese una dirección de correo electrónico válida.',
      telephoneLabel: 'Teléfono',
      telephoneHelperText: 'Ingrese su número de teléfono',
      documentLabel: 'Documento de Identificación',
      documentHelperText: 'Ingrese su número de Identificación',
      dateinputLabel: 'Fecha de Nacimiento',
      dateinputHelperText: 'Ingrese su Fecha de Nacimiento',
      genderLabel: 'Género',
      genderHelperText: 'Seleccione un género',
      mandatoryField: 'Requerido',
      countryLabel: 'País',
      countryHelperText: 'Seleccione un País',
      uploadPhotoText: 'Subir foto',
      uploadPhotoHelperText: 'Envía tu mejor foto a nuestros registros.',
      uploadDocumentPhotoText: 'Subir foto del documento',
      uploadDocumentPhotoHelperText:
        'Subir una imagen clara de su documento con foto (por ejemplo: ID, PASAPORTE)',
      uploadDocumentRequired: 'Se requiere la carga de su documento fotográfico',
      uploadPhotoRequired: 'Se requiere la carga de su foto',
      question1Label: '¿De donde vienes?',
      questionHelperText: 'Responda esta pregunta',
      question2Label: '¿Cúal es tu próximo destino?',
      question3Label: '¿Cúal es el motivo de tu viaje?',
      question4Label:
        '¿Cúal es la razón principal por la cual escojiste nuestro hotel?',
      question5Label:
        '¿Le gustaría recibir información sobre buenos hoteles en su próxima parada?',
      acceptTermsLabel: 'Leer y aceptar los términos y condiciones',
      acceptTermsHelperText: 'Debe aceptar los términos y condiciones',
      successTitle: 'Éxito!',
      successSubTitle: 'Checkin exitoso.',
      successText: 'Su información ha sido grabada con éxito',
      moreAboutYouLabel: 'Cuentanos mas sobre ti',
      errorTitle: 'Error!',
      errorSubTitle: 'Ocurrió un error',
      errorText: 'Ocurrió un error al intentar guardar, haz clic en Siguiente',
      dateformat: 'dd/MM/yyyy',
      emptyCheckin: 'No tiene huéspedes a los cuales registrar',
      termsAndConditionsTitle: 'Términos y Condiciones',
      checkinNextLabel: 'Registrar otro Huesped',
      shareLinkLabel: 'Compartir',
      shareLinkCopyLabel: 'Copiar Link p/ Compartir',
      shareLinkWhatsappLabel: 'Compartir p/ Whatsapp',
      shareCheckinOnlineMsg:
        'Haz Check in online en su reserva através del link ',
      shareLinkEmailLabel: 'Compartir p/ E-mail',
      linkSharedLabel: 'Link copiado',
      addressLabel: 'Dirección',
      addressHelperText: 'Ingrese su dirección',
      numberLabel: 'Número',
      numberHelperText: 'Digite o número do seu endereço',
      complementLabel: 'Complemento',
      complementHelperText: 'Digite o complemento do seu endereço',
      neighborhoodLabel: 'Bairro',
      neighborhoodHelperText: 'Digite seu bairro',
      taxIdLabel: 'CPF',
      taxIdHelperText: 'Digite seu CPF',
      cityLabel: 'Ciudad',
      cityHelperText: 'Ingrese su ciudad',
      stateLabel: 'Estado o Región',
      stateHelperText: 'Ingrese su Estado',
      postalCodeLabel: 'Código Postal',
      postalCodeHelperText: 'Ingrese su código postal',
      paymentLabel: 'Pago',
      debitLabel: 'Saldo de reserva pendiente:',
      ccCardNumberLabel: 'Numero de tarjeta',
      ccFullNameLabel: 'Nombre',
      ccValidThruLabel: 'Validez',
      ccCodeLabel: 'CVC',
      ccYourName: 'Nombre',
      ccPaymentAmount: 'Valor a Pagar',
      paymentInfo:
        '* Si la reserva ya esté pagada o si desea utilizar otro método de pago, ',
      paymentInfoAction: 'haga clic AQUÍ',
      paymentAmountInputHelperText:
        'El valor no puede exceder el monto total adeudado.',
      ccCardNumberInputHelperText: 'El número informado no es válido.',
      ccFullNameInputHelperText: 'Informar el nombre impreso en la tarjeta.',
      ccValidThruInputHelperText: 'Informar una fecha válida.',
      ccCodeInputHelperText: 'Informar un código válido.'
    }
  }
}
