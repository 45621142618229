import React from 'react'
import Grid from '@material-ui/core/Grid'
import ErrorRed from '../assets/error_red.png'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import red from '@material-ui/core/colors/red'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { withTranslate } from 'react-redux-multilingual'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

class Error extends React.Component {
  gotoHome = () => {
    window.location.reload()
  }

  render() {
    const { classes, translate, message } = this.props

    return (
      <React.Fragment>
        <Grid container spacing={10}>
          <Grid item xs={12} style={{ marginTop: 10, textAlign: 'center' }}>
            <img src={ErrorRed} alt="tick" height="100" width="100" />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography component="h4" variant="h4">
              {translate('errorTitle')}
            </Typography>
            <Typography component="h4" variant="h4">
              {translate('errorSubTitle')}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography component="h6" variant="h6">
              {message !== '' ? message : translate('errorText')}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              className={classNames(classes.margin, classes.cssRoot)}
              onClick={this.gotoHome}
            >
              {translate('nextLabel')}
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

Error.propTypes = {
  classes: PropTypes.object.isRequired
}

const styles = (theme) => ({
  margin: {
    margin: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  cssRoot: {
    color: '#fff',
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700]
    }
  }
})

export default compose(withStyles(styles), connect())(withTranslate(Error))
