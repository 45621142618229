import States from '../services/states';

export default {
  async getCountryStates(data) {

    let countryStates;

    try {
      countryStates = await States.getCountryStates(data);
    } catch (error) {
      countryStates = null;
    }

    return countryStates ? countryStates.data : null;
  }
}