import Properties from '../services/properties'

export default {
  async findProperty(data) {
    let property

    try {
      property = await Properties.findProperty(data)
    } catch (error) {
      property = false
    }

    return property ? property.data : false
  },
  async connectProperty(data) {
    let property

    try {
      property = await Properties.connectProperty(data)
    } catch (error) {
      property = false
    }

    return property ? property.data : false
  },
  async findReservationByInfo(endpoint, data) {
    let property

    try {
      property = await Properties.findReservationByInfo(endpoint, data)
    } catch (error) {
      property = false
    }

    return property ? property.data : false
  }
}
