import Gender from '../services/genders'

export default {
  async getGenders(data) {
    let gender

    try {
      gender = await Gender.getGenders(data)
    } catch (error) {
      gender = false
    }

    return gender ? gender.data : false
  }
}
