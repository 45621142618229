export function getWhatsappLink(url, title, isMobile) {
  return (
    'https://' +
    (isMobile ? 'api' : 'web') +
    '.whatsapp.com/send?text=' +
    title +
    ' ' +
    url
  )
}

export function getEmailLink(subject, body) {
  return 'mailto:?subject=' + subject + '&amp;body=' + body
}

export function isPaymentEnabled() {
  let enabled = true;

  if (process.env.hasOwnProperty('REACT_APP_GUEST_GENDER')) {
    enabled = +process.env.PAYMENT_ENABLED === 1;
  }

  return enabled;
}
