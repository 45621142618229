import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FileInputComponent from 'react-file-input-previews-base64'
import Profile from '../assets/profile.png'
import Fab from '@material-ui/core/Fab'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import { withTranslate } from 'react-redux-multilingual'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

class BookingInfoPhoto extends React.Component {
  constructor(props) {
    super(props)

    this.onCropped = this._onCropped.bind(this)
  }

  state = {
    imgUrl: Profile,
    crop: {
      x: 10,
      y: 10,
      width: 50,
      height: 50
    },
    croppedImageUrl: null
  }

  _onCropped = (e) => {
    this.setState({ imgUrl: e.image })
  }

  getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement('canvas')

    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    const context = canvas.getContext('2d')

    context.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    )

    return new Promise((resolve) => {
      canvas.toDataURL('image/jpeg')
    })
  }

  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image
    this.makeClientCrop(this.state.crop, pixelCrop)
  }

  onCropComplete = (crop, pixelCrop) => {
    this.makeClientCrop(crop, pixelCrop)
  }

  onCropChange = (crop) => {
    this.setState({ crop })
  }

  makeClientCrop(crop, pixelCrop) {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(
        this.imageRef,
        pixelCrop,
        'newFile.jpeg'
      ).then((croppedImageUrl) => this.setState({ croppedImageUrl }))
    }
  }

  getPhoto = (photo) => {
    return new Promise((resolve) => {
      const width = 200,
        image = new Image()

      image.onload = () => {
        const canvas = document.createElement('canvas'),
          context = canvas.getContext('2d'),
          auxCanvas = document.createElement('canvas'),
          auxContext = auxCanvas.getContext('2d')

        let ratio = 1

        if (image.width > width) {
          ratio = width / image.width
        }

        auxCanvas.width = image.width
        auxCanvas.height = image.height

        auxContext.drawImage(image, 0, 0)

        canvas.width = image.width * ratio
        canvas.height = image.height * ratio

        context.drawImage(
          auxCanvas,
          0,
          0,
          auxCanvas.width,
          auxCanvas.height,
          0,
          0,
          canvas.width,
          canvas.height
        )

        resolve(canvas.toDataURL('image/jpeg', 1))
      }

      image.src = photo
    })
  }

  render() {
    const { classes, translate } = this.props

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12} className={classes.image}>
            <img
              src={this.state.imgUrl}
              alt="profilePhoto"
              style={{
                width: 200,
                height: 'auto',
                resizeMode: 'cover',
                borderRadius: '50%'
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.title} component="h2">
              {translate('uploadPhotoText')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.text} component="p">
              {translate('uploadPhotoHelperText')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FileInputComponent
              labelText=""
              parentStyle={{ textAlign: 'center' }}
              imagePreview={false}
              labelStyle={{ fontSize: 14 }}
              multiple={true}
              imageStyle={{ borderRadius: '50%' }}
              callbackFunction={(file) => {
                this.getPhoto(file[0].base64).then((url) => {
                  this.props.checkin.photo = url.split(';base64,')[1]
                  this.setState({ imgUrl: url })
                  this.props.checkSelfImageUpload()
                })
              }}
              accept="image/*"
              buttonComponent={
                <Fab color="primary" aria-label="Add" className={classes.fab}>
                  <PhotoCamera />
                </Fab>
              }
            />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

BookingInfoPhoto.propTypes = {
  classes: PropTypes.object.isRequired
}

const styles = (theme) => ({
  root: {
    textAlign: 'center'
  },
  image: {
    marginTop: 10,
    textAlign: 'center',
    borderRadius: '50%'
  },
  fab: {
    margin: theme.spacing(1)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  text: {
    color: '#757575',
    textAlign: 'center'
  },
  title: {
    color: '#000',
    textAlign: 'center',
    fontSize: 24
  }
})

export default compose(
  withStyles(styles),
  connect()
)(withTranslate(BookingInfoPhoto))
