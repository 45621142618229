import Places from '../services/places'

export default {
  async findPlace(data) {
    let places

    try {
      places = await Places.findPlace(data)
    } catch (error) {
      places = null
    }

    return places ? places.data : null
  }
}
