import { server } from './config'
import client from './client'

export default {
  findProperty(data = {}, config = {}) {
    return client.get(`${server}/checkin/api/findProperty?query=${data}`)
  },
  connectProperty(data = {}, config = {}) {
    return client.get(`${server}/checkin/api/connect?property=${data}`)
  },
  findReservationByInfo(endpoint, data = {}, config = {}) {
    return client.get(
      `${endpoint}/findReservationByInfo?query=${data.query}&propertyId=${data.propertyId}&language=${data.language}`
    )
  }
}
