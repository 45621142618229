import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import withStyles from '@material-ui/core/styles/withStyles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/NavigateNext'
import Fab from '@material-ui/core/Fab'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import Divider from '@material-ui/core/Divider'
import ErrorRed from '../assets/error_red.png'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Reservation from '../modules/reservation'
import Properties from '../modules/properties'
import queryString from 'query-string'
import { withTranslate } from 'react-redux-multilingual'
import CustomAppBar from './CustomAppBar'
import Loading from './Loading'
import Checkin from './Checkin'
import 'typeface-roboto'

import Autocomplete from '@material-ui/lab/Autocomplete'

class Home extends React.Component {
  state = {
    loading: this.props.loading,
    //
    isShowLink: false,
    isFindReservation: false,
    isFindStart: true,
    endPointFindReservation: '',
    reservationId: '',
    connectPropertyId: null,
    reservationList: [],
    reservationLoaded: false,
    connectDefaultLanguage: 'en',
    //
    error: false,
    errorText: '',
    expanded: this.props.bookingId && this.props.propertyId,
    setExpanded: false,
    expanded1: false,
    setExpanded1: false,
    on: false,
    on2: false,
    isFindProperty: false,
    docheckin: false,
    checkin: null,
    searchName: '',
    searchEmail: '',
    propertyInfo: {
      property_name: '',
      contact_email: '',
      address: '',
      phone: '',
      logo: ErrorRed
    },
    bookingInfo: {
      check_in_date: '',
      check_out_date: '',
      totalGuests: 0,
      rooms: []
    },
    dateIn: new Date(),
    dateOut: new Date(),
    externalData: null,
    selectedRoom: '',
    selectedHospede: -1,
    totalHospedes: 0,
    anchorEl: null,
    findProperties: [],
    findLoaded: true
  }

  constructor(props) {
    super(props)

    this.findReservation = this.findReservation.bind(this)
    this.gotoCheckin = this.gotoCheckin.bind(this)
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value
    })
  }

  async componentDidMount() {
    if (process.env.hasOwnProperty('REACT_APP_LOCATOR_CODE')) {
      this.setState({ reservationId: process.env.REACT_APP_LOCATOR_CODE })
    }

    if (this.props.propertyId && this.props.bookingId) {
      const defaultLocale = this.props.Intl.locale
      const checkin = await Reservation.getCheckin(defaultLocale)

      if (!checkin) {
        this.setState({ externalData: '404' })
      } else {
        this.setState({
          checkin: checkin.result,
          propertyInfo: checkin.result.propertyInfo,
          bookingInfo: checkin.result.bookingInfo,
          loading: false,
          dateIn: checkin.result.bookingInfo.formatted_check_in_date,
          dateOut: checkin.result.bookingInfo.formatted_check_out_date
        })
      }
    }

    /***
     * Request with URL params /demo for example
     */
    const { pathname } = window.location

    if (String(pathname).length >= 2) {
      let _pathname = String(pathname).replace('/', '')
      this.setState({ loading: true })
      const connect = await Properties.connectProperty(_pathname)
      if (connect.status === true) {
        if (localStorage.getItem('hqbeds@property') !== _pathname) {
          localStorage.removeItem('hqbeds@property')
        }

        if (!localStorage.getItem('hqbeds@property')) {
          let _locate = 'en'
          // eslint-disable-next-line default-case
          switch (connect.result.default_language) {
            case 'pt-br':
              _locate = 'pt'
              break
            case 'es':
              _locate = 'es'
              break
          }

          this.props.setLocale(_locate)

          localStorage.setItem('hqbeds@property', _pathname)
        }

        this.setState({
          isShowLink: true,
          loading: false,
          isFindReservation: false,
          isFindProperty: false,
          isFindStart: true,
          connectPropertyId: connect.result.propertyId,
          endPointFindReservation: connect.result.api_endpoint,
          connectDefaultLanguage:
            this.props.Intl.locale === 'en'
              ? this.props.Intl.locale
              : connect.result.default_language
        })
      } else {
        this.setState({
          loading: false
        })
      }
    }
  }

  render() {
    const { classes, translate } = this.props
    const {
      isFindProperty,
      isFindStart,
      isFindReservation,
      dateIn,
      dateOut,
      isShowLink
    } = this.state

    if (this.state.docheckin) {
      return (
        <Checkin
          roomName={this.state.selectedRoom}
          hospede={this.state.selectedHospede}
          totalGuests={this.state.totalHospedes}
          roomId={this.state.selectedRoomID}
        />
      )
    } else {
      return (
        <React.Fragment>
          <Loading visible={this.state.loading} />
          <div style={{ display: this.state.loading ? 'none' : 'block' }}>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <CustomAppBar />
              <main className={classes.layout}>
                {isFindStart && (
                  <Card className={classes.card} raised={true}>
                    <div style={{ display: this.state.expanded ? 'none' : 'visible' }}>
                      <CardHeader
                        title="Checkin"
                        subheader={translate('homeSubHeader')}
                      />
                      <CardContent>
                        <TextField
                          error={this.state.error}
                          id="reservationId"
                          label={translate('localizadorLabel')}
                          className={classes.textField}
                          value={this.state.reservationId}
                          helperText={this.state.errorText}
                          onChange={this.handleChange('reservationId')}
                          margin="normal"
                          onKeyPress={this.onKeyPress}
                          inputProps={{ maxLength: 15 }}
                        />
                      </CardContent>
                      <CardActions className={classes.actions}>
                        <Fab
                          variant="extended"
                          aria-label="Seguindo"
                          className={classes.fab}
                          onClick={this.findReservation}
                        >
                          <ExpandMoreIcon className={classes.extendedIcon} />
                          {translate('nextLabel')}
                        </Fab>
                      </CardActions>
                      {isShowLink && (
                        <p className={classes.orClH}>
                          {translate('or')}{' '}
                          <Button
                            className={classes.orClHLnk}
                            style={{ backgroundColor: 'transparent' }}
                            onClick={this.gotoFindProperty}
                          >
                            {translate('clickHere')}
                          </Button>{' '}
                          {translate('andSearchForYourData')}
                        </p>
                      )}
                    </div>
                    <Collapse
                      in={this.state.expanded}
                      timeout="auto"
                      unmountOnExit
                      classes={{ wrapperInner: classes.mydiv }}
                    >
                      <div className={classes.details}>
                        <div className={classes.detailsHeader}>
                          <Grid item xs={12} sm={7} className={classes.propertyDetails}>
                            <CardContent className={classes.content}>
                              <Typography component="h5" variant="h5">
                                {this.state.propertyInfo.property_name}
                              </Typography>
                              <Typography variant="subtitle1" color="textSecondary">
                                {this.state.propertyInfo.address}
                              </Typography>
                              <Typography variant="subtitle1" color="textSecondary">
                                {translate('phoneLabel') + this.state.propertyInfo.phone}
                              </Typography>
                              <Typography variant="subtitle1" color="textSecondary">
                                E-Mail: {this.state.propertyInfo.contact_email}
                              </Typography>
                              <Divider variant="middle" className={classes.divideSection} />
                              <Typography component="h5" variant="h5">
                                {translate('reservationTitle')}
                              </Typography>
                              <Typography variant="subtitle1" color="textSecondary">
                                Check in: {String(dateIn)}
                              </Typography>
                              <Typography variant="subtitle1" color="textSecondary">
                                Check out: {String(dateOut)}{' '}
                              </Typography>
                              <Typography variant="subtitle1" color="textSecondary">
                                {translate('guestsLabel') + this.state.bookingInfo.totalGuests}{' '}
                              </Typography>
                            </CardContent>
                          </Grid>
                          <Grid item xs={12} sm={5} className={classes.propertyImage}>
                            <CardMedia
                              className={classes.cover}
                              image={this.state.propertyInfo.logo}
                              title="Property Logo"
                            />
                          </Grid>
                        </div>
                        <Grid item className={classes.roomDetails}>
                          <CardHeader title={translate('chooseRoomLabel')} />
                          <CardContent className={classes.content}>
                            {this.state.bookingInfo.rooms.map(
                              (room, index, rooms) => (
                                <div className={classes.section2} key={room.id}>
                                  <Typography variant="subtitle1">
                                    {room.roomName}
                                  </Typography>
                                  <Typography variant="subtitle1">
                                    {translate('guestSubtitle') + room.emptyGuests}
                                  </Typography>
                                  <Button
                                    variant="contained"
                                    disabled={room.emptyGuests <= 0}
                                    color="default"
                                    onClick={() =>
                                      this.gotoCheckin(
                                        room.roomName,
                                        room.guests,
                                        room.emptyGuests,
                                        room.id
                                      )
                                    }
                                  >
                                    Checkin
                                    <PlayArrowIcon className={classes.rightIcon} />
                                  </Button>
                                  <Divider
                                    className={classes.divideSection}
                                    style={{
                                      display: rooms.length - 1 === index ? 'none' : 'visible'
                                    }}
                                  />
                                </div>
                              )
                            )}
                          </CardContent>
                        </Grid>
                      </div>
                    </Collapse>
                  </Card>
                )}
                {isFindProperty && (
                  <Card className={classes.card} raised={true}>
                    <CardHeader
                      title="Checkin"
                      subheader={translate('findPropertySubHeader')}
                    />
                    <CardContent>
                      <Autocomplete
                        id="combo-box-demo"
                        options={this.state.findProperties}
                        getOptionLabel={(option) => option.label}
                        onChange={(a, b) => this.handleChangeSelectProperty(b)}
                        style={{ width: '100%' }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={translate('findPropertyByName')}
                            onChange={this.handleChangeFindProperty}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </CardContent>
                  </Card>
                )}
                {isFindReservation && (
                  <Card className={classes.card} raised={true}>
                    <CardHeader
                      title="Checkin"
                      subheader={translate('findYourReservationByNameOrEmail')}
                    />
                    <CardContent width="100%">
                      <TextField
                        error={this.state.error}
                        id="searchEmail"
                        label={translate('txtEmail')}
                        className={classes.textField}
                        value={this.state.searchEmail}
                        helperText={this.state.errorText}
                        onChange={this.handleChange('searchEmail')}
                        margin="normal"
                        onKeyPress={this.onKeyPress}
                      />
                      <CardActions className={classes.actions}>
                        <Fab
                          variant="extended"
                          aria-label="Seguindo"
                          className={classes.fab}
                          onClick={this.gotoReservation}
                        >
                          <ExpandMoreIcon className={classes.extendedIcon} />
                          {translate('search')}
                        </Fab>
                      </CardActions>
                      {/*  */}
                      {this.state.reservationLoaded && (
                        <TableContainer component={Paper}>
                          <Table style={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  {translate('tableHeaderName')}
                                </TableCell>
                                <TableCell>
                                  {translate('tableHeaderResId')}
                                </TableCell>
                                <TableCell>
                                  {translate('tableHeaderCheckin')}
                                </TableCell>
                                <TableCell>
                                  {translate('tableHeaderCheckout')}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.reservationList.map((row) => (
                                <TableRow
                                  key={row.name}
                                  className={classes.tableRowHandle}
                                  onClick={() =>
                                    this.handleClickReservation(row)
                                  }
                                >
                                  <TableCell>
                                    {row.name} {row.surname}
                                  </TableCell>
                                  <TableCell>
                                    {row.property_booking_id}
                                  </TableCell>
                                  <TableCell>{row.check_in_date}</TableCell>
                                  <TableCell>{row.check_out_date}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </CardContent>
                  </Card>
                )}
              </main>
            </MuiThemeProvider>
          </div>
        </React.Fragment>
      )
    }
  }

  handleClickReservation = (row) => {
    this.setState({ loading: true })
    try {
      window.open(`${row.checkin_online_url}`, '_self')
    } catch (e) {
      this.setState({
        loading: false
      })
    }
  }

  gotoReservation = async () => {
    this.setState({ loading: true })
    try {
      const info = await Properties.findReservationByInfo(
        this.state.endPointFindReservation,
        {
          query: this.state.searchEmail,
          propertyId: this.state.connectPropertyId,
          language:
            this.props.Intl.locale === 'en'
              ? this.props.Intl.locale
              : this.state.connectDefaultLanguage
        }
      )
      const { status, result } = info

      if (status === true) {
        this.setState({
          reservationLoaded: true,
          reservationList: result.reservations,
          loading: false
        })
      } else {
        this.setState({
          loading: false
        })
      }
    } catch (e) {
      this.setState({
        loading: false
      })
    }
  }

  handleChangeSelectProperty = async (property) => {
    this.setState({ loading: true })

    const connect = await Properties.connectProperty(property.id)

    if (connect.status === true) {
      this.setState({
        loading: false,
        isFindReservation: true,
        isFindProperty: false,
        isFindStart: false,
        connectPropertyId: connect.result.propertyId,
        endPointFindReservation: connect.result.api_endpoint,
        connectDefaultLanguage:
          this.props.Intl.locale === 'en'
            ? this.props.Intl.locale
            : connect.result.default_language
      })
    }
  }

  handleChangeFindProperty = async (event) => {
    const _value = event.target.value

    if (_value.length >= 3) {
      if (this.state.findLoaded) {
        this.state.findLoaded = false
        const property = await Properties.findProperty(event.target.value)
        if (property.status === true) {
          this.setState({ findProperties: property.result.propertyList })
          this.state.findLoaded = true
        } else {
          this.state.findLoaded = true
        }
      }
    } else {
      this.setState({ findProperties: [] })
    }
  }

  handleNext = () => { }

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  onKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      this.findReservation()
    }
  }

  handleExpandClick1 = () => {
    const expanded = !this.state.expanded1

    this.setState({
      expanded1: expanded,
      on: expanded,
      on2: this.state.setExpanded2
    })
  }

  findReservation = () => {
    const reservationId = this.state.reservationId
    const defaultLocale = this.props.Intl.locale

    if (reservationId === '') {
      this.setState({
        error: true,
        errorText: this.props.translate('localizadorError')
      })
    } else {
      this.setState({ loading: true })

      Reservation.findReservation({ reservationId }).then((reponse) => {
        if (reponse.status) {
          let uri = reponse.result.edp,
            urlData = queryString.parseUrl(uri)

          sessionStorage.setItem('url', reponse.result.base)
          sessionStorage.setItem('locale', defaultLocale)
          sessionStorage.setItem('bookingId', urlData.query.bookingId)
          sessionStorage.setItem('propertyId', urlData.query.propertyId)

          // get current rooms
          Reservation.getCheckin(defaultLocale).then((checkin) => {
            if (!checkin) {
              this.setState({
                error: true,
                errorText: this.props.translate('localizadorNotFound'),
                loading: false
              })
            } else {
              if (checkin.status) {
                try {
                  const expanded = !this.state.expanded

                  this.setState({
                    checkin: checkin.result,
                    propertyInfo: checkin.result.propertyInfo,
                    bookingInfo: checkin.result.bookingInfo,
                    externalData: '',
                    setExpanded: expanded,
                    expanded: expanded,
                    error: false,
                    errorText: '',
                    loading: false,
                    docheckin: false,
                    dateIn: checkin.result.bookingInfo.formatted_check_in_date,
                    dateOut: checkin.result.bookingInfo.formatted_check_out_date
                  })
                } catch (ex) {
                  this.setState({
                    error: true,
                    errorText: this.props.translate('localizadorNotFound'),
                    loading: false
                  })
                }
              } else {
                this.setState({
                  error: true,
                  errorText: this.props.translate('localizadorNotFound'),
                  loading: false
                })
              }
            }
          })
        } else {
          this.setState({
            error: true,
            errorText: this.props.translate('localizadorNotFound'),
            loading: false
          })
        }
      })
    }
  }

  gotoFindProperty = async () => {
    this.setState({ loading: true })
    if (this.state.connectPropertyId !== null) {
      this.setState({
        loading: false,
        isFindStart: false,
        isFindProperty: false,
        isFindReservation: true
      })
    } else {
      this.setState({
        loading: false,
        isFindStart: false,
        isFindProperty: true,
        isFindReservation: false
      })
    }
  }

  //init checkin of selected room and hospede
  gotoCheckin = (roomName, totalGuests, emptyGuests, roomId) => {
    let guest = totalGuests - emptyGuests + 1
    this.setState({
      selectedRoomID: roomId,
      selectedRoom: roomName,
      selectedHospede: guest,
      totalHospedes: totalGuests,
      docheckin: true
    })
  }
}

const styles = (theme) => ({
  tableRowHandle: {
    cursor: 'pointer'
  },
  appBar: {
    position: 'relative',
    backgroundColor: '#303e46'
  },
  orClHLnk: {
    textDecoration: 'none',
    color: '#303e46',
    cursor: 'pointer',
    textTransform: 'uppercase',
    background: 'none',
    padding: 0
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(4))]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(6))]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  paperTitle: {
    [theme.breakpoints.up(800 + theme.spacing(6))]: {
      marginBottom: theme.spacing(3),
      padding: theme.spacing(3)
    },
    backgroundColor: '#fff'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginTop: 0,
    marginLeft: 0,
    marginRight: theme.spacing(1),
    width: '100%'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  title: {
    color: '#000'
  },
  card: {
    maxWidth: 800,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(6))]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  orClH: {
    padding: '0 16px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  actions: {
    display: 'flex',
    padding: '16px'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  fab: {
    margin: theme.spacing(1),
    marginLeft: 0
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  details: {
    width: '100%',
    overflow: 'auto'
  },
  detailsHeader:{
    display: 'flex',
    flex: '1 1',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    }
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 250,
    height: 250,
    backgroundSize: 'contain'
  },
  controls: {
    alignItems: 'center',
    padding: 16
  },
  playIcon: {
    height: 38,
    width: 38
  },
  mydiv: {
    display: 'flex'
  },
  section2: {},
  divideSection: {
    marginLeft: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  quarto: {
    '&:active': {
      backgroundColor: 'rgb(199, 217, 47)'
    }
  },
  quartoActive: {
    backgroundColor: '#c7d92f'
  },
  roomDetails: {
    width: '100%',
    clear: 'left'
  },
  propertyDetails: {
    float: 'left'
  },
  propertyImage: {
    float: 'left',
    order: -1
  }
})

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    useNextVariants: true
  },
  palette: {
    primary: {
      main: '#455A64'
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      contrastText: '#ffcc00'
    }
  }
})

const mapStateToProps = (state) => ({ ...state })

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    setLocale: (locale) => dispatch({ type: 'SET_LOCALE', locale })
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslate(Home))
